.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1040;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media print {
  .print-content {
    font-size: 10px; /* Reduced font size for printing */
    margin: 3px 0; /* Reduced margin for printing */
  }
}
/* Full-screen modal covering entire width and height */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Ensure modal content takes full screen */
.modal-content {
  background: white;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  padding: 20px;
  overflow: auto; /* Enable scrolling if content is too large */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  display: flex;
  flex-direction: column;
}

/* Close & Print buttons */
.close-btn,
.print-btn {
  position: absolute;
  top: 15px;
  right: 20px;
  background: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.close-btn:hover,
.print-btn:hover {
  background: #0056b3;
}

.print-btn {
  right: 100px; /* Position print button beside close button */
}

/* Printable content should expand fully */
.printable-content {
  flex-grow: 1;
  width: 100%;
  text-align: center;
}

/* Ensure full-width printing */
@media print {
  /* body * {
    visibility: hidden;
  } */
  .modal-content,
  .modal-content * {
    visibility: visible;
  }
  .modal-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
  }
  .close-btn,
  .print-btn {
    display: none;
  }
}
.spinner {
  height: 25px;
  width: 25px;
  margin: 0 auto 0 auto;
  position: relative;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 4px solid #fff;
  border-right: 4px solid #fff;
  border-bottom: 4px solid #fff;
  border-top: 4px solid #0099ff;
  border-radius: 100%;
  display: inline-block;
  vertical-align: middle;
}

/* TODO Add vendor prefixes if you need them */
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
