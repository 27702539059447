.a1 {
  width: 50% " aria-valuenow=" 50 " aria-valuemin=" 0 " aria-valuemax=" 100
    ";
}
.a2
{
    width: 20%" aria-valuenow= "20" aria-valuemin= "0" aria-valuemax=
    "100";
}
.a3 {
  width: 40% " aria-valuenow=" 40 " aria-valuemin=" 0 " aria-valuemax=" 100
    ";
}
.a4
{
    width: 80%" aria-valuenow= "80" aria-valuemin= "0" aria-valuemax=
    "100";
}
.a5 {
  width: 100% " aria-valuenow=" 100 " aria-valuemin=" 0 " aria-valuemax=" 100
    ";
}
.a6
{
    width: 25rem;
}
.a7
{
    width: 60%" aria-valuenow= "60"
    aria-valuemin= "0" aria-valuemax= "100";
}
.toggled {
  overflow: visible;
  width: 6.5rem !important;
}
.toggled1 {
  width: 0 !important;
  overflow: hidden;
  /* color: #e96793 */
}
@media print {
  body {
    zoom: 100%;
    font-size: 18px;
    font-weight: bold;
    color: #000;
    line-height: 1.5; /* Improve readability */
  }

  .container-fluid {
    border: 2px solid #344767; /* Apply border to the outer div */
    padding: 20px; /* Adjust padding as needed */
    padding-left: 30px;
    padding-right: 40px;
    background-color: #fff; /* Ensure background is white for clear printing */
  }

  .inner-content {
    border: none; /* Remove border from inner content */
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  td,
  th {
    font-size: 18px; /* Increase font size for better readability */
    font-weight: bold;
    padding: 10px; /* Increase padding for better spacing */
  }

  h1,
  h5,
  p {
    font-size: 18px; /* Increase font size for better readability */
    font-weight: bold;
    margin: 10px 0; /* Add margin for better spacing */
  }

  p {
    font-size: 20px; /* Increase font size for paragraph text */
  }
  p b {
    font-size: 20px; /* Increase font size for paragraph text */
  }
  p span {
    font-size: 20px;
  }
  p div {
    font-size: 25px;
  }
  ol li {
    font-size: 30px;
  }

  .no-print {
    display: none;
  }
}
